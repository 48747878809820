import { I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS } from "./intake.status.js";
import { WRITEOFF_PERMISSION, MAINTAIN_PERMISSION, GROWERS_OFFSITESTORAGE } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", {
    attrs: {
      "title": "Offsite Storage",
      "search-by": "IntakeNumber",
      "ph-search-by": "Search by Intake"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_vm.hasWriteoffPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Writeoff")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "file-excel",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.redirectUrl(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("url-field", {
    key: "IntakeNumber",
    attrs: {
      "data-index": "intakeNumber",
      "title": "Intake",
      "base-url": "/watties-grower/offsite-storage#",
      "sorter": true
    }
  }), _c("text-field", {
    key: "TruckCode",
    attrs: {
      "data-index": "truck",
      "title": "Truck"
    }
  }), _c("text-field", {
    key: "Grower",
    attrs: {
      "data-index": "grower",
      "title": "Grower",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Crop",
    attrs: {
      "data-index": "crop",
      "title": "Crop"
    }
  }), _c("text-field", {
    key: "BpcsIntake",
    attrs: {
      "data-index": "bpcsIntake",
      "title": "BPCS Intake"
    }
  }), _c("text-field", {
    key: "TimeIn",
    attrs: {
      "data-index": "timeIn",
      "title": "Time In",
      "parse": _vm.parseTimeIn
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus
    }
  })], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListOffsiteStorage",
  inject: ["can"],
  computed: {
    hasWriteoffPermission() {
      return this.can(WRITEOFF_PERMISSION);
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    parseTimeIn(value) {
      if (!value)
        return;
      return this.$moment(value).format("MM/DD/YY");
    },
    redirectUrl(value) {
      this.$router.push(`/watties-grower/offsite-storage/${value.id}/writeoff`);
    },
    getStatusColor(value) {
      return INTAKE_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return INTAKE_STATUS_TEXTS[value];
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListOffsiteStorage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.offsite-storages",
      "api-url": _vm.apiUrl,
      "edit-route": "offsite-storage/:id/edit",
      "filter-route": "offsite-storage/advanced-search",
      "page": _vm.page
    }
  }, [_c("list-offsite-storage")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "OffsiteStorage",
  components: {
    ListOffsiteStorage
  },
  data() {
    return {
      apiUrl,
      ListOffsiteStorage,
      page: GROWERS_OFFSITESTORAGE,
      menuItems: [
        {
          key: "offsitestorage",
          title: "Offsite Storage",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
